.main {
    margin: 40px;
    padding: 50px;
}

img {

    width: 493px;
    hight: 469px;

}

.main_second {
    margin-left: 20px;
    color: #282C34;
    width: 35%;
    left: 600px;
    font-size: 30px;
}

span {
    color: #2B4CA9;
}

.main_first, .main_second {
    vertical-align: middle;
    horiz-align: center;
    display: inline-block;
}

p {
    margin-top: -30px;
    font-size: 16px;
}

.profile_pic {
    width: 100%;
    height: 100%;
    border-radius: 100px;
}


@media (max-width: 575.98px) {
    .main_second {
        margin-left: 20px;
        color: #282C34;
        width: 87%;
        left: 600px;
        font-size: 30px;
    }


    .main {
        margin: 10px;
        padding: 0px;
    }

    img {
        width: 212px;

    }


    .App {

        padding: 0px;
    }
}

