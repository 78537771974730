li {
    display: inline;
    margin-top: -30px;
    margin-right: 10px;
    font-size: 20px;
}

li, a {
    text-decoration: none;
    color: #282C34;

}